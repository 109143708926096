<template>
  <div id="information">
    <div class="top"></div>
    <div class="news wrapper">
      <div class="title">
        <router-link to="/dash">首页<span v-html="'&nbsp'"></span></router-link>
        <router-link to="/information"
          >-<span v-html="'&nbsp'"></span>资讯<span v-html="'&nbsp'"></span
        ></router-link>
        <a href="javascript:void(0)"
          >-<span v-html="'&nbsp'"></span>住房租赁市场回暖
          监管强化助力住房租赁市场规范发展</a
        >
      </div>
      <div class="content">
        <el-row>
          <el-col :span="18" class="left">
            <div class="news_title">
              <div class="newsDate">
                <div class="year">2021-10</div>
                <div class="day">09</div>
              </div>
              <div class="newsText">
                住房租赁市场回暖 监管强化助力住房租赁市场规范发展
              </div>
            </div>
            <div class="news_content">
              <p>
                今年三季度，此前受到新冠肺炎疫情影响的住房租赁市场逐渐回温，部分地市出现量价齐升情况，市场逐步修复。与此同时，住建部本月上旬公布了《住房租赁条例（征求意见稿）》，行业监管进一步强化。
              </p>
              <p>
                业内人士认为，完善租购并举的住房制度，更好发展住房租赁市场，不仅是房地产长效调控政策之一，通过规范市场，稳定租赁关系，也是避免房地产市场大起大落的重要举措。
              </p>
              <p class="bold">逐步走出疫情影响住房租赁市场逐步修复</p>
              <p>
                湖北留学生小吴因疫情影响，暂时中断学业，7月份回国经过隔离观察后在上海找到一份传媒公司的实习工作。经过一番考察，她在上海静安区以每月7800元价格租到一个面积50平方米左右的公寓。
              </p>
              <p>
                中介工作人员告诉她，这个价格比二季度上涨了大约8%左右，预计接下来还会再涨。小吴说，“尽管价格比较高，但因为需要上网课，有时差，怕打扰室友，所以就选择独自租。”
              </p>
              <p>
                小吴的租房经历是个缩影，相关数据也显示，受到疫情影响的住房租赁市场正在逐步恢复。58同城、安居客近日发布的《2020年三季度楼市总结》报告显示，今年前三季度，受春节假期及新冠肺炎疫情双重影响，1月至2月租房市场需求下降明显。三季度，19个重点城市租房访问热度同比去年微涨0.3%。从其平台数据看，重庆、郑州、长沙、东莞租房访问热度同比涨幅均超8%。
              </p>
              <p>
                在租金价格方面，相关数据也显示，疫情影响逐渐减弱。58同城、安居客数据显示，今年三季度，19个重点城市的平均租金为每月每平方米41.1元，同比仅下降0.4%。一线城市中，上海平均租金同比持平，其余三城租金同比均有不同程度下跌。杭州租金价格为每月每平方米52.7元，南京、武汉、天津租金超过每月每平方米40元，其他城市的租金价格多在每月每平米20元至40元之间。
              </p>
              <p>
                贝壳研究院数据显示，8月，北京租赁市场成交量达到年内峰值，租金价格环比上涨。北京第三季度成交量环比上涨34%，7月、8月成交量环比连续上涨，并在8月达到年内峰值。第三季度北京平均租金为每月每平方米85.2元，环比上涨2.2%，同比下降3%。
              </p>
              <p class="bold">“不得诱导使用租金贷”住房租赁行业监管不断强化</p>
              <p>
                在市场恢复的同时，对住房租赁市场的监管和规范也在进一步强化。
              </p>
              <p>
                本月初，住建部网站公布了《住房租赁条例（征求意见稿）》（以下简称“《条例》征求意见稿”），在出租与承租、租赁企业、经纪活动、法律责任等方面提出60多条规范措施，明确提出严控长租公寓领域“高进低出”“租金贷”等现象，规范住房租赁合同网签备案，稳定各地租金水平。
              </p>
              <p>
                在住房租赁行业，尤其是长租公寓领域，“租金贷”问题往往让租户防不胜防。记者此前采访中了解到，杭州一位租户以每月2300元的价格，通过某长租公寓企业租住一间合租房屋。在选择付款方式时，平台管家极力推荐“押一付一”的付款方式。该租户说，因刚毕业，收入少，相比“押一付三”，这一付款方式有很大吸引力。不过签订合同后，租户才发现，密密麻麻的合同条款中有一项显示，选择“押一付一”的付款方式将自动同某信托公司签署贷款合同。
              </p>
              <p>
                此次公布的《条例》征求意见稿提出，住房租赁企业不得以隐瞒、欺骗、强迫等方式要求承租人使用住房租金贷款，不得以租金优惠等名义诱导承租人使用住房租金贷款，不得在住房租赁合同中包含租金贷款相关内容。
              </p>
              <p>
                此外，《条例》征求意见稿还提出，商业银行发放住房租金贷款，应当以备案的住房租赁合同为依据，贷款期限不得超过住房租赁合同期限。
              </p>
              <p>
                北京大学房地产法研究中心主任楼建波说，在租赁市场相对稳定的情况下，诱导使用“租金贷”风险往往被掩盖，一旦市场出现风吹草动，比如疫情会放大风险，租赁企业资金链条断裂，房东和承租人就都成为受害者。
              </p>
              <p>
                业内人士指出，“租金贷”就是长租公寓平台以租客租金的稳定性作为筹码，从第三方贷款平台获取到贷款，之后用贷款去进行投资活动赚取高额利润，而租客也就变为了贷款人，租客支付租金也就成了还贷，一旦长租公寓平台投资行为出现问题，由此产生的问题是连贯的，从贷款平台到租户，以及房东都会受到牵连。
              </p>
              <p>
                目前市场上的长租公寓平台多采用以合同的形式从房东手里吸纳房源，再以居间合同的形式撮合租户与房东进行租房交易。一方面，这种商业模式门槛较低，另一方面也使得平台极力扩大市场规模以博取更高估值。
              </p>
              <p>
                对此，《条例》征求意见稿中也明确了相关要求，对租赁企业和房地产经纪机构明确区分，禁止住房租赁企业及其从业人员有哄抬租金、捆绑消费等扰乱市场秩序的行为。在服务与监督要求上，对于住房租赁企业支付房东租金高于租户租金、收取租户租金周期长于房东租金周期的高风险经营行为，房产管理名录要将其列入经营异常名录，加强对租金、押金等经营情况的监管。
              </p>
              <p>
                易居研究院智库中心研究总监严跃进认为，此次《条例》征求意见稿对长租公寓经营不善给予关注，其中对“高进低出”和“长收短付”等给予重点关注。“类似规定有较为积极的意义，若上述行为受到抑制，客观上会减少长租公寓风险。”
              </p>
              <p class="bold">加大对承租人权益保障助力住房租赁市场高质量发展</p>
              <p>
                实行购租并举，培育和发展住房租赁市场，是深化住房制度改革的重要内容，也是实现城镇居民住有所居目标的重要途径。相关报告显示，我国住房租赁市场总规模已达1.5万亿元，其中北上广深一线城市合计超过5500亿元，占比超35%。
              </p>
              <p>
                加大对承租人权益保障，一方面，各地出台不同举措。日前，《西安市规范住房租赁市场管理办法》印发，对住房租赁活动予以规范。西安市住房租赁交易服务平台也已于今年8月18日启动上线运行工作，要求所有住房租赁企业都应当在系统注册；所有房源都应当通过系统对外公布；住房租赁合同实行网签备案。
              </p>
              <p>
                另一方面，在供给端，部分地市也力求供给多元化。在上海，近年来，包括上海城投、上海地产、上海张江在内的众多地方国企进军长租公寓行业，相关产品将从今年起集中上市。和既有“轻资产”长租公寓运营模式不同，由国企投资运营的长租公寓项目普遍为“重资产”运营模式，即企业负责拿地、建设、运营管理等多个环节。业内人士认为，这将成为住房租赁市场的“稳定器”和“压舱石”。
              </p>
              <p>
                《条例》征求意见稿还明确，出租人应为承租人提供必要的居住空间。厨房、卫生间、阳台和地下储藏室等非居住空间，不得出租用于居住，市级以上地方人民政府应当规定必要的居住空间标准，明确符合当地实际的单间租住人数和人均租住面积。这一要求将有效遏制群租房等乱象。
              </p>
              <p>
                58安居客房产研究院分院院长张波说：“总体来看，本次《条例》征求意见稿对于租赁的规范更为全面和细致，对于目前常见的侵害到租赁关系人的事项进行了明确规范，还可更有效指导各地落实更为本地化的租赁类政策。”
              </p>
              <p>
                针对“租金随意涨”等问题，根据《条例》征求意见稿，“直辖市、设区的市级人民政府应当建立住房租赁指导价格发布制度，对于租金上涨过快的，可以采取必要措施稳定租金水平。”
              </p>
              <p>
                严跃进说，租金指导制度将加快建立，体现了监管层对于租赁市场引导的导向，即形成地方“基准租金”的概念。与此同时，租金公示制度将加快建立，后续各个区域和不同住房都会公布相关租金信息，完善租金监管制度。
              </p>
              <p>
                中原地产首席分析师张大伟说，只有租赁市场稳定了，买卖市场才能稳定，尽管租赁市场目前还存在部分乱象，但随着政策的重视，租赁市场稳定和回归理性可以预期。
              </p>
            </div>
          </el-col>
          <el-col :span="5" class="right">
            <div class="recommendTitle">推荐</div>
            <div class="recommendList">
              <div class="recommendItem">
                <router-link to="/help1">
                  <img src="../../assets/images/help/help1.jpeg" alt="" />
                  <div class="msg">
                    房屋装修注意事项(菜鸟看后装修无遗憾)--水电安装注意事项
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/help2">
                  <img src="../../assets/images/help/help3.jpeg" alt="" />
                  <div class="msg">租房也可变Dream House</div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help3">
                  <img src="../../assets/images/help/help2.png" alt="" />
                  <div class="msg">
                    房东利益如何最大化之合理装修
                  </div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help4">
                  <img src="../../assets/images/help/help4.png" alt="" />
                  <div class="msg">自建房的房屋租赁税计算</div></router-link
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#information {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/information/资讯背景.png);
    background-size: cover;
  }
  .news {
    .title {
      padding: 12px 0;
      margin-top: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #d2d2d2;
      a {
        color: #5a5e66;
      }
    }
    .content {
      padding-bottom: 50px;
      .left {
        margin-top: 25px;
        .news_title {
          color: #000;
          overflow: hidden;
          .newsDate {
            float: left;
            width: 55px;
            height: 55px;
            background-color: #436993;
            color: #fff;
            padding: 6px 2px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            .year {
              font-size: 12px;
            }
            .day {
              font-size: 28px;
            }
          }
        }
        .newsText {
          height: 55px;
          line-height: 55px;
          padding-left: 70px;
          font-size: 20px;
          background-color: #f1f3f6;
        }
        .news_content {
          text-align: left;
          color: #000;
          .bold {
            font-weight: 700;
            font-size: 18px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: rgb(51, 51, 51);
            text-align: justify;
            line-height: 24px;
          }
        }
      }
      .right {
        margin: 25px 0 0 50px;
        img {
          width: 200px;
          height: 115px;
        }
        background: #f2f6fa;
        min-height: 300px;
        .recommendTitle {
          color: #000;
          text-align: left;
          font-weight: bold;
          padding: 0 10px;
          margin: 20px 0;
          border-left: 3px solid #3b6798;
        }
        .recommendList {
          padding: 0 0 10px;
          .recommendItem {
            padding: 0 10%;
            margin-bottom: 15px;
            .msg {
              color: #717171;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
